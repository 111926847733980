import { Link } from "react-router";

import { useState } from "react";
import { getFormatedDayHour } from "~/services/date";

/**
 * The breakpoint for smaller screens is 404px:
 * 12px gutter (x2) + 2 cards (180px x2) + gap (20px)
 */

export default function PlayCard({
  title,
  imageUrl = "/placeholder.svg?height=400&width=300",
  location,
  datetime,
  href,
  attribution,
}) {
  const [imageLoading, setImageLoading] = useState(true);
  const time = datetime ? getFormatedDayHour(datetime) : null;

  return (
    <Link
      to={href}
      className={`
      w-[180px]
      flex
      flex-col
      gap-2
      relative
    `}
    >
      <div
        className={`
          bg-black
          border-r
          text-left
          overflow-hidden
          
          cursor-pointer
          min-w-none
          max-w-none
          w-[180px]
          
          min-h-[240px]
          flex
          flex-row
          items-center
          gap-1
          rounded
          shadow-md
          
          hover:shadow-lg
          transition-all
          hover:-translate-y-1.5
        `}
        tabIndex={0}
        role="link"
      >
        <div className="flex-1 aspect-[1/1.4] relative h-[270px] w-[180px]">
          {imageLoading && <div className="absolute inset-0 bg-gray-300 animate-pulse rounded-none" />}
          <img
            src={imageUrl}
            alt={`Poster for ${title}`}
            className={`w-full h-full object-cover  rounded-none p-0 ${
              imageLoading ? "opacity-0" : "opacity-100"
            } transition-opacity duration-300`}
            onLoad={() => setImageLoading(false)}
          />
        </div>
      </div>
      <div className="space-y-1 py-0 overflow-hidden">
        <h2 className="text-base font-semibold text-gray-800 block">
          <span className="line-clamp-2">{title}</span>
        </h2>

        <div className="flex items-center">
          <span className="text-xs font-semibold text-gray-500 truncate">{location || attribution}</span>
        </div>

        <div className="flex items-center">
          <span className="text-xs text-gray-500 font-semibold">{time}</span>
        </div>

        <div className="hidden text-right text-xl absolute bottom-1 right-2 p-0 font-semibold text-gray-300">
          &rsaquo;
        </div>
      </div>
    </Link>
  );
}

export function CollapsablePlayCard({
  title,
  imageUrl = "/placeholder.svg?height=400&width=300",
  location,
  datetime,
  href,
  attribution,
}) {
  const [imageLoading, setImageLoading] = useState(true);
  const time = datetime ? getFormatedDayHour(datetime) : null;

  return (
    <Link
      to={href}
      className={`
      w-full
      border
      min-[404px]:border-none
      rounded
      min-[404px]:w-[180px]
      flex
      flex-row
      min-[404px]:flex-col
      gap-2
      
      relative
      shadow
      min-[404px]:shadow-none
    `}
    >
      <div
        className={`
          bg-black
          border-r
          text-left
          overflow-hidden
          
          hover:shadow-lg
          cursor-pointer
          min-w-[30%]
          max-w-[30%]
          w-[30%]
          min-[404px]:max-w-none
          min-[404px]:min-w-none
          min-[404px]:w-[180px]
          min-[404px]:block
          min-[404px]:min-h-[240px]
          flex
          flex-row
          items-center
          gap-1
          min-[404px]:rounded
          min-[404px]:shadow-md
          
          transition-all
        
          min-[404px]:hover:shadow-lg
        `}
        tabIndex={0}
        role="link"
      >
        <div className="flex-1 aspect-[1/1.4] relative min-[404px]:h-[270px] min-[404px]:w-[180px]">
          {imageLoading && <div className="absolute inset-0 bg-gray-300 animate-pulse min-[404px]:rounded-none" />}
          <img
            src={imageUrl}
            alt={`Poster for ${title}`}
            className={`min-[404px]:w-full h-full object-cover  min-[404px]:rounded-none p-0 min-[404px]:p-0 ${
              imageLoading ? "opacity-0" : "opacity-100"
            } transition-opacity duration-300`}
            onLoad={() => setImageLoading(false)}
          />
        </div>
      </div>
      <div className="space-y-1 py-2 min-[404px]:py-0 overflow-hidden">
        <h2 className="text-lg min-[404px]:text-base font-semibold text-gray-800 block">
          <span className="line-clamp-2">{title}</span>
        </h2>

        <div className="flex items-center">
          <span className="min-[404px]:text-xs font-semibold text-gray-500 truncate">{location || attribution}</span>
        </div>

        <div className="flex items-center">
          <span className="text-sm min-[404px]:text-xs text-gray-500 font-semibold">{time}</span>
        </div>

        <div className="min-[404px]:hidden text-right text-xl absolute bottom-1 right-2 p-0 font-semibold text-gray-300">
          &rsaquo;
        </div>
      </div>
    </Link>
  );
}
